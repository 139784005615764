import {createTheme} from "@mui/material";
import '@fontsource/oswald/200.css';
import '@fontsource/oswald/300.css';
import '@fontsource/oswald/400.css';
import '@fontsource/oswald/500.css';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const theme = createTheme({
  palette:{
    primary:{
      light: '#fafafa',
      medium: '#ececec',
      main: '#424242',
      dark: '#424242',
      contrastText: '#212121',
    },
    secondary:{
      light: '#fff8df',
      main: '#ffc800',
      dark: '#ff8b00',
      contrastText: '#fff',
    }
  },

  typography:{
    h1:{
      fontFamily: 'Oswald, Arial, sans-serif',
      fontWeight: "900",
      '@media (min-width:0px)': {
        fontSize: '2.5rem',
        lineHeight: '3.5rem',
      },
      '@media (min-width:600px)': {
        fontSize: '3rem',
        lineHeight: '4rem',
      },
      '@media (min-width:960px)': {
        fontSize: '4rem',
        lineHeight: '5rem',
      },
      '@media (min-width:1080px)': {
        fontSize: '4.5rem',
        lineHeight: '6rem',
      },
    },
    h2:{
      fontFamily: 'Oswald, Arial, sans-serif',
      fontWeight: "500",
      '@media (min-width:0px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width:960px)': {
        fontSize: '2rem',
      },
      '@media (min-width:1080px)': {
        fontSize: '2rem',
      },
    },
    h3:{
      fontFamily: 'Roboto, Arial, sans-serif',
      fontWeight: 500,
      '@media (min-width:0px)': {
        fontSize: '1rem',
      },
      '@media (min-width:600px)': {
        fontSize: '1.1rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.2rem',
      },
      '@media (min-width:1080px)': {
        fontSize: '1.2rem',
      },
    },
    h4:{
      fontFamily: 'Roboto, Arial, sans-serif',
      fontWeight: 400,
      marginTop: '1rem',
      '@media (min-width:0px)': {
        fontSize: '1rem',
      },
      '@media (min-width:600px)': {
        fontSize: '1.1rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.2rem',
      },
      '@media (min-width:1080px)': {
        fontSize: '1.2rem',
      },
    },
    body1:{
      fontFamily: 'Roboto, Arial, sans-serif',
      fontWeight: 300,
      '@media (min-width:0px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.1rem',
      },
      '@media (min-width:1080px)': {
        fontSize: '1.1rem',
      },
    },
    body2:{
      fontFamily: 'Roboto, Arial, sans-serif',
      fontWeight: 500,
      '@media (min-width:0px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.1rem',
      },
      '@media (min-width:1080px)': {
        fontSize: '1.1rem',
      },
    },
    button:{
      fontFamily: 'Roboto, Arial, sans-serif',
      fontWeight: 400,
      textTransform: 'uppercase',
      '@media (min-width:0px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.1rem',
      },
      '@media (min-width:1080px)': {
        fontSize: '1.1rem',
      },
    },
    overline: {
      fontFamily: 'Roboto, Arial, sans-serif',
      fontWeight: "400",
      textTransform: 'capitalize',
      '@media (min-width:0px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.1rem',
      },
      '@media (min-width:1080px)': {
        fontSize: '1.1rem',
      },
    },
  },
})

export default theme;