import {
  AppBar,
  Box,
  Container,
  Toolbar,
} from "@mui/material";
import theme from "../Theme";
import DesktopMenu from "../components/navigation/DesktopMenu";
import CVDownload from "../components/navigation/CVDownload";
import MobileMenu from "../components/navigation/MobileMenu";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import About from "../components/About";
import Skills from "../components/Skills";
import Portfolio from "../components/Portfolio";
import Contact from "../components/Contact";
import Imprint from "./Imprint";

function Navbar(props) {
  const location = useLocation();
  const isImprint = location.pathname.includes("impressum");
  const navigate = useNavigate();

  const aboutRef = useRef(null);
  const skillsRef = useRef(null);
  const portfolioRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        aboutRef.current?.scrollIntoView({ behavior: "smooth" });
        break;

      case "/skills":
        skillsRef.current?.scrollIntoView({ behavior: "smooth" });
        break;

      case "/portfolio":
        portfolioRef.current?.scrollIntoView({ behavior: "smooth" });
        break;

      case "/contact":
        contactRef.current?.scrollIntoView({ behavior: "smooth" });
        break;

      default:
        break;
    }
  }, [location.pathname]);

  const renderContent = () => {
    if (isImprint) {
      return <Imprint />;
    } else {
      return (
        <>
          <div ref={aboutRef} id="about">
            <About />
          </div>
          <div ref={skillsRef} id="skills" style={{ paddingTop: 80 }}>
            <Skills />
          </div>
          <div ref={portfolioRef} id="portfolio" style={{ paddingTop: 80 }}>
            <Portfolio />
          </div>
          <div ref={contactRef} id="contact" style={{ paddingTop: 80, paddingBottom: 80 }}>
            <Contact />
          </div>
        </>
      );
    }
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          boxShadow: 0,
          backgroundColor: theme.palette.primary.light,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            <Box
              component="img"
              sx={{
                height: 64,
                cursor: "pointer",
              }}
              alt="AE."
              src={require("../components/images/logo.png")}
              onClick={() => navigate("/")}
            />

            <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
              <MobileMenu />
            </Box>

            <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
              <DesktopMenu />
            </Box>

            <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
              <CVDownload />
            </Box>
          </Toolbar>
        </Container>
        <Outlet />
      </AppBar>
      {renderContent()}
    </>
  );
}

export default Navbar;
