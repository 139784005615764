import {Drawer, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {useState} from "react";
import theme from "../../Theme";
import CVDownload from "./CVDownload";
import {useNavigate} from "react-router-dom";

function MobileMenu () {

  const [anchorElNav, setAnchorElNav] = useState(false);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    handleClose();
  };

  const handleClose = () => {
    setAnchorElNav(null);
  };

  const handleCloseNavMenu = (newPage) => {
    setAnchorElNav(null);
    switch (newPage) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/skills');
        break;
      case 2:
        navigate('/portfolio');
        break;
      case 3:
        navigate('/contact');
        break;
      default:
        break;
    }
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="website menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit">
        <MenuIcon/>
      </IconButton>
      <Drawer
        anchor='top'
        open={anchorElNav}
        onClose={handleCloseNavMenu}
      >
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          PaperProps={{
            style: {
              borderRadius: 0,
              backgroundColor: theme.palette.primary.light,
              width: '100vw',
            },
            elevation: 0,
          }}
          sx={{
            display: {xs: 'block', md: 'none'},
          }}
        >
          <MenuItem onClick={() => handleMenuItemClick('')}>
            <Typography variant="overline" textAlign="center">About</Typography>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('/skills')}>
            <Typography variant="overline" textAlign="center">Skills</Typography>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('/portfolio')}>
            <Typography variant="overline" textAlign="center">Portfolio</Typography>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('/contact')}>
            <Typography variant="overline" textAlign="center">Kontakt</Typography>
          </MenuItem>
          <MenuItem>
            <CVDownload/>
          </MenuItem>
        </Menu>
      </Drawer>
    </>
  );
}

export default MobileMenu;