import {Container, Link, Toolbar, Typography} from "@mui/material";
import theme from "../Theme";
import {useLocation, useNavigate} from "react-router-dom";

function Footer (props) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={location.pathname==='/impressum' ? 'footer' : ''}>
      <div className="bgContainer blackBG">
        <Container maxWidth="xl"
                   sx={{
                     minHeight: '5rem',
                     alignContent: 'center'
                   }}
        >
          <Toolbar disableGutters sx={{justifyContent: "space-between"}}>
            <Typography variant="body1" sx={{color: theme.palette.primary.light}}>© Annika Elze 2024</Typography>
            <Typography variant="body1" sx={{color: theme.palette.primary.light}}>
              <Link sx={{
                color: theme.palette.primary.light,
                cursor: 'pointer',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                }
              }}
                    onClick={() => navigate('/impressum')}
              >
                Impressum
              </Link>
            </Typography>
          </Toolbar>
        </Container>
      </div>
    </div>
  )
}

export default Footer;