import {useEffect, useState} from "react";
import {styled, Tab, Tabs} from "@mui/material";
import theme from "../../Theme";
import {useLocation, useNavigate} from "react-router-dom";

function DesktopMenu (props) {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handlePageNavigation = (event, newPage) => {
    setPage(newPage);
    switch (newPage) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/skills');
        break;
      case 2:
        navigate('/portfolio');
        break;
      case 3:
        navigate('/contact');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (location.pathname === "/impressum" || location.pathname === "/privacy-policy") {
      setPage(-1);
    } else if (location.pathname === "/about") {
      setPage(0);
    } else if (location.pathname === "/skills") {
      setPage(1);
    } else if (location.pathname === "/portfolio") {
      setPage(2);
    } else if (location.pathname === "/contact") {
      setPage(3);
    }
  }, [location]);

  return (
    <StyledTabs
      value={page}
      onChange={handlePageNavigation}
      aria-label="navigation menu"
    >
      <StyledTab label="About"/>
      <StyledTab label="Skills"/>
      <StyledTab label="Portfolio"/>
      <StyledTab label="Kontakt"/>
    </StyledTabs>
  )
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 0,
    width: '100%',
    backgroundColor: theme.palette.primary.contrastText,
  },
});

const StyledTab = styled((props) =>
    <Tab disableRipple {...props} />
  )(
  ({ theme }) => ({
    textTransform: 'none',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.contrastText,
    typography: theme.typography.overline,
    '&.Mui-selected': {
      color: theme.palette.primary.contrastText,
      overflow: 'hidden',
    },
    transition: 'background-color 0.3s, transform 0.3s',
    '&:hover': {
      backgroundColor: "transparent",
      transform: 'scale(1.09)',
      overflow: 'hidden',
    },
  }),
);

export default DesktopMenu;