export const projects = {
  project1: {
    title: "MINOR e. V. Website",
    date: "seit Juli 2020",
    text: "Im Rahmen meiner ehrenamtlichen Tätigkeit bei MINOR e. V., pflege ich seit Juli 2020 die Website des Sportvereins. " +
      "Die Website wurde mit WordPress erstellt und wird fortlaufend aktualisiert, verändert und erweitert.",
    link: "https://minor-pole.de",
    skills: {
      1: "WordPress",
      2: "HTML | CSS | JavaScript",
    }
  },
  project2: {
    title: "MINOR e. V. Social Media",
    date: "seit Juli 2020",
    text: "Neben der Website von MINOR e. V. betreue ich, ebenfalls im Rahmen meiner ehrenamtlichen Tätigkeit, " +
      "die Social Media Kanäle des Vereins. " +
      "Mein Aufgabenbereich umfasst das Planen und Erstellen von Beiträgen und Storys, sowie " +
      "das Erstellung und Bearbeiten von Videos, Fotos und Grafiken vorrangig für die Plattform Instagram.",
    link: "https://www.instagram.com/minor_poledancedresden/",
    skills: {
      1: "Instagram",
      3: "Canva",
      4: "Affinity Products",
      5: "Adobe Lightroom",
      6: "DaVinci Resolve",
    }
  },
  project3: {
    title: "Leitstellenanwendung für autonom fahrende Busse",
    date: "Mai 2021",
    text: "Im Mai 2021 führte ich die Entwicklung eines Mockups für die Überwachung autonom fahrender Busse in " +
      "Zusammenarbeit mit der Technischen Visualistik GmbH weiter. Grundlage bildeten die Erkenntnisse aus meiner Bachelorarbeit," +
      " in der ich mich mit der Konzeption eines Fahrgastkommunikationssystems für autonome Verkehrsmittel beschäftigte.",
    link: "https://computervision.visualistik.eu/projects/leitstelle",
    skills: {
      1: "Adobe XD",
    }
  },
  project4: {
    title: "Konzeption von Lehr- und Lernszenarien an einer 3D-Tafel",
    date: "Juli 2021",
    text: "Im Juli 2021 veröffentlichte ich zusammen mit weiteren Autor*innen eine wissenschaftliche Publikation " +
      "zur Konzeption und Umsetzung von Lehr- und Lernszenarien unter Verwendung einer 3D-Tafel.",
    link: "https://www.researchgate.net/publication/359899145_Konzeption_und_Umsetzung_von_Lehr-_und_Lernszenarien_unter_Verwendung_einer_prototypischen_3D-Tafel_-_als_Beitrag_innovativer_digitaler_Medien_in_Schulen",
    skills: {
      1: "Adobe XD",
      2: "Adobe Illustrator",
    }
  },
  project5: {
    title: "UI-Konzept für die Überwachung autonomer öffentlicher Verkehrsmittel",
    date: "September 2021",
    text: "Basierend auf meiner Bachelorarbeit entwickelte ich in Zusammenarbeit mit weiteren Autor*innen ein UI-Konzpet " +
      "für die Überwachung autonomer öffentlicher Verkehrsmittel. Die wissenschaftliche Publikation wurde im " +
      "September 2021 veröffentlicht.",
    link: "https://www.researchgate.net/publication/354677924_Control_the_Autonomous_--_User_Interfaces_for_Monitoring_and_Dispatching_Autonomous_Vehicles",
    skills: {
      1: "Adobe XD"
    }
  },
  project6: {
    title: "Konzeption von projektionsbasierter visueller Unterstützung bei Sportübungen",
    date: "September 2022",
    text: "Im Rahmen eines Komplexpraktikums an der TU Dresden erarbeitete ich ein Konzept für die visuelle " +
      "Unterstützung von Sportübungen. In Zusammenarbeit mit weiteren Autor*innen wurde das Konzept in einer " +
      "wissenschaftlichen Publikation im September 2022 veröffentlicht.",
    link: "https://dl.acm.org/doi/abs/10.1145/3543758.3549018",
    skills: {
      1: "Adobe XD",
      2: "HTML | CSS | JavaScript",
    }
  },
  project7: {
    title: "Echtzeit Feedback im Kontext von Sport und Bewegung",
    date: "April 2024",
    text: "In meiner Masterarbeit entwickelte ich eine prototypische Webanwendung für Echtzeit-Feedback zur " +
      "Unterstützung motorischen Lernens im Kontext von Sport und Bewegung. Die Anwendung basiert auf einem " +
      "neuronalen Netzwerk und wurde unter Verwendung von React entwickelt.",
    link: "https://www.researchgate.net/publication/380922775_Automated_Real-Time_Feedback_in_Sports_and_Body_Movements_Neural_Network-Based_Web_App_for_Posture_Correction",
    skills: {
      1: "React",
      2: "MediaPipe"
    }
  }
}