import {Box, Container, Grid, Paper, styled} from "@mui/material";
import Section from "./ui-components/Section";
import {skills} from "./information/skills";
import Skill from "./ui-components/Skill";

const SkillItem = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 0,
  borderRadius: 0,
  boxShadow: 'none',
  color: theme.palette.primary.contrastText,
}));

function Skills () {
  return (
    <div className="bgContainer lightBG">
      <Container maxWidth="xl">
        <Section section={"Skills"}/>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 3, md: 5 }} columns={{ xs: 1, sm: 8, md: 12 }}>
            {Object.keys(skills).map((item, i) => (
              <Grid item xs={2} sm={4} md={4} key={i}>
                <SkillItem>
                  <Skill  skill={skills[item]}/>
                </SkillItem>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </div>
  )
}

export default Skills;