import './App.css';
import theme from "./Theme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import Navbar from "./pages/Navbar";
import {BrowserRouter} from "react-router-dom";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App" style={{ overflowX: 'hidden', height: '100vh', backgroundColor: theme.palette.primary.light}}>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <BrowserRouter>
          <Navbar />
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
