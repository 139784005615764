import {Container, Typography} from "@mui/material";
import {useEffect} from "react";

function About () {

  useEffect(() => {
    const img = new Image();
    const mobileImg = new Image();
    img.src = '/src/components/images/annikaelze.jpg';
    mobileImg.src = '/src/components/images/annikaelze-mobile.jpg';
  }, []);


  return (
    <>
      <div className="bgContainer hero-section darkBG">
        <Container maxWidth="xl"
                   sx={{
                     minHeight: '80vh',
                     alignContent: {
                        xs: 'end',
                        sm: 'center',
                     },
                     paddingBottom: 3,
                   }}
        >
          <Typography variant="h1">Hi,<br/> ich bin Annika</Typography>
          <div className="about-text-desktop">
            <Typography variant="body2">
              Als Medieninformatikerin [M. Sc.] bin ich begeistert von allem rund um UI|UX-Design,
              Webentwicklung und Social Media.
              Mit Erfahrungen aus beruflichen und privaten Projekten halte ich meine Skills fortlaufend
              auf dem neuesten Stand und freue mich immer über neue kreative Herausforderungen.
            </Typography>
          </div>
        </Container>
      </div>
    </>
  )
}

export default About;