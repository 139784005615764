import {Box, Container, Grid, Paper, styled, Typography} from "@mui/material";
import SkillScale from "./SkillScale";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: 'none',
  textAlign: 'start',
  padding: 0,
  color: theme.palette.primary.contrastText,
}));

function Skill (props) {
  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={1} sm={1} md={1}  style={{ paddingLeft: 0 }}>
            <Box display="flex" justifyContent="flex-start">
              {props.skill.icon}
            </Box>
          </Grid>
          <Grid item xs={11} sm={11} md={11}>
            <Item>
              <Typography variant="h3">
                {props.skill.title}
              </Typography>
            </Item>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl">
        <Grid container spacing={2} alignItems="center" sx={{marginTop: "0rem"}}>
          <Grid item xs={1} sm={1} md={1}>
          </Grid>
          <Grid item xs={11} sm={11} md={11}>
            <Item>
              {Object.keys(props.skill.tools).map((item, i) => (
                <SkillScale skill={props.skill.tools[item]} key={item}/>
              ))}
            </Item>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Skill;