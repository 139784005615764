import {Box, styled, Typography} from "@mui/material";
import theme from "../../Theme";

function ContactLink (props) {

  const ClickableBox = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    transition: 'transform 0.3s ease, background-color 0.3s ease',
    '&:hover': {
      transform: 'scale(1.06)',
    },
  }));

  return (
    <ClickableBox
      display="flex"
      alignItems="center"
      onClick={() => {
        window.open(props.link, '_blank');
      }}
    >
      <Box
        sx={{
          width: 40,
          height: 40,
          borderRadius: "50%",
          backgroundColor: theme.palette.primary.dark,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: theme.palette.primary.light,
          marginRight: 1,
        }}
      >
        {props.icon}
      </Box>
      <Typography variant="body1" color="textPrimary">
        {props.label}
      </Typography>
    </ClickableBox>
  )
}

export default ContactLink;