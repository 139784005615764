import {Typography} from "@mui/material";

function Section (props) {
  return (
    <div className={"section-title"}>
      <Typography variant="h2">{props.section}</Typography>
    </div>
  )
}

export default Section;