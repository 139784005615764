import {ButtonBase, Card, CardContent, Chip, Typography} from "@mui/material";
import theme from "../../Theme";

function Project (props) {

  const handleCardClick = () => {
    window.open(props.project.link, '_blank');
  };

  const card = (
    <CardContent>
      <Typography variant="body1" gutterBottom>
        {props.project.date}
      </Typography>

      <Typography variant="h3"
                      sx={{
                        marginBottom: 2,
                      }}>
        {props.project.title}
      </Typography>

      {Object.keys(props.project.skills).map((item, i) => (
        <Chip label={props.project.skills[item]}
              size="small"
              key={item}
              sx={{
                borderRadius: 0,
                marginRight: 0.6,
                marginBottom: 0.6,
                backgroundColor: theme.palette.primary.medium,
              }}/>
      ))}

      <Typography variant="body1"
                  sx={{
                    marginTop: 2,
                  }}>
        {props.project.text}
      </Typography>
    </CardContent>
  );

  return (
    <ButtonBase
        onClick={handleCardClick}
        disableRipple
        sx={{
          textAlign: 'inherit',
          width: '100%',
          height: '100%',
          transition: 'background-color 0.3s, transform 0.3s',
          '&:hover': {
            backgroundColor: "transparent",
            transform: 'scale(1.02)',
            overflow: 'hidden',
          },
        }}
      >
      <Card variant="outlined"
            sx={{
              borderRadius: 0,
              background: "transparent",
              height: '100%',                // Make the card take up full height
              display: 'flex',                // Use flexbox to control layout
              flexDirection: 'column',        // Arrange content vertically
              border: "1px solid",
              borderColor: theme.palette.primary.contrastText,
            }}
      >
        {card}
      </Card>
    </ButtonBase>
  )
}

export default Project;