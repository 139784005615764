import theme from "../../Theme";
import {Button} from "@mui/material";

function CVDownload () {

  const openCV = () => {
    const cv = "/cv-annikaelze.pdf";
    window.open(cv, '_blank');
  };

  return (
    <Button
      onClick={openCV}
      sx={{
        my: 2,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.contrastText,
        border: 2,
        borderRadius: 0,
        paddingX: '1.5rem',
        display: 'block',
        ':hover': {
          backgroundColor: theme.palette.primary.medium,
        }
      }}
    >
      Download CV
    </Button>
  )
}

export default CVDownload;