import {Box, Container, Typography} from "@mui/material";
import theme from "../Theme";
import ContactLink from "./ui-components/ContactLink";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ContactIcon from "./ui-components/ContactIcon";
import { FaLinkedinIn } from "react-icons/fa";

function Contact () {

  return (
    <div className="bgContainer lightBG">
      <Container maxWidth="xl"
                 sx={{
                   display: 'flex',
                   justifyContent: 'center'
                 }}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '40rem',
            },
            borderRadius: 0,
            backgroundColor: theme.palette.primary.medium,
            display: 'flex',
            alignItems: {
              xs: 'left',
              sm: 'center',
            },
            flexDirection: 'column',
            padding: 5,
          }}
        >
          <Typography variant="h2">Kontakt</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
              alignItems: {
                xs: 'left',
                sm: 'center',
              },
              gap: {
                xs: 3,
                sm: 10,
              },
              marginTop: 5,
            }}>
            <ContactLink
              icon={<ContactIcon IconComponent={FaLinkedinIn}/>}
              link={"https://www.linkedin.com/in/annika-elze-59897629a/"}
              label={"Annika Elze"}
            />
            <ContactLink
              icon={<ContactIcon IconComponent={EmailOutlinedIcon}/>}
              link={"mailto:mail@annikaelze.de"}
              label={"mail@annikaelze.de"}
            />
          </Box>
        </Box>
      </Container>
    </div>
  )
}

export default Contact;