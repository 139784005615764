import {Box, Rating, Typography} from "@mui/material";
import theme from "../../Theme";

let RatingIcon = (props) => {
  if (props.status === "active") {
    return (
      <svg height="14" width="14" className="ratingIcon">
        <circle r="6" cx="7" cy="7" fill={theme.palette.primary.contrastText} />
        Sorry, your browser does not support inline SVG.
      </svg>
    )
  } else {
    return (
      <svg height="14" width="14" className="ratingIcon">
        <circle r="5" cx="7" cy="7" fill="transparent" stroke={theme.palette.primary.contrastText} strokeWidth="1"/>
        Sorry, your browser does not support inline SVG.
      </svg>
    )
  }

}

function SkillScale(props) {
  return (
    <div className="simpleGrid">
      <Typography variant="body1">
        {props.skill.title}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="end" height="100%">
        <Rating
          readOnly
          value={props.skill.level}
          icon={<RatingIcon status={"active"}/>}
          emptyIcon={<RatingIcon status={"inactive"}/>}
        />
      </Box>
    </div>
  )
}

export default SkillScale;