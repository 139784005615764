import {useMediaQuery} from '@mui/material';
import theme from "../../Theme";

function ResponsiveIcon ({IconComponent}) {
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <IconComponent fontSize={isLargeScreen ? 'large' : 'medium'} sx={{
      stroke: theme.palette.primary.light,
      padding: 0,
      strokeWidth: {
        xs: 0.7,
        sm: 1,
      },
    }}/>
  )
}

export default ResponsiveIcon;