import {Container, Grid, Paper, styled} from "@mui/material";
import Section from "./ui-components/Section";
import {projects} from "./information/projects";
import Project from "./ui-components/Project";

const ProjectItem = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 0,
  borderRadius: 0,
  boxShadow: 'none',
  color: theme.palette.primary.contrastText, display: 'flex',             // Add flex display
  flexDirection: 'column',     // Arrange items in a column
  justifyContent: 'space-between', // Spread content to fill the height
  height: '100%',              // Ensure Paper takes full height of Grid item

}));

function Portfolio () {
  return (
    <div className="bgContainer lightBG">
      <Container maxWidth="xl">
        <Section section={"Portfolio"}/>
        <Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
          {Object.keys(projects).map((item, i) => (
            <Grid item xs={2} sm={4} md={4} key={i}>
              <ProjectItem>
                <Project  project={projects[item]}/>
              </ProjectItem>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  )
}

export default Portfolio;