import {Container, Link, Typography} from "@mui/material";
import Section from "../components/ui-components/Section";
import theme from "../Theme";

function Imprint () {
  return (
    <Container maxWidth="xl"
               sx={{
                 paddingY: 5,
               }}
    >
      <Section section={"Impressum"}/>
      <div className="imprint">
        <div className="section">
          <Typography variant="h3">
            Anbieterin und inhaltlich verantwortliche Person
          </Typography>
          <Typography variant="body1">
            Annika Elze
          </Typography>
        </div>
        <div className="section">
          <Typography variant="h3">
            Kontakt
          </Typography>
          <Link href="mailto:mail@annikaelze.de"
                style={{
                  textDecoration: 'none',
                  color: theme.palette.primary.contrastText
                }}>
            <Typography variant="body1">
              E-Mail: mail@annikaelze.de
            </Typography>
          </Link>
          <Typography variant="body1">
            Anschrift: Jagdweg 1-3, 01159 Dresden
          </Typography>
        </div>
      </div>
    </Container>
  )
}

export default Imprint;